import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import Arrow from "../assets/Arrow.svg";

export default function ProjectCard({ name, image, role, description, tech, techName, link, sourceCode, isMobile, isTablet }) {
  const divRef = useRef(null),
        imageRef = useRef(null),
        arrowRef = useRef(null),
        timelineRef = useRef(null);
  useEffect(() => {
    const timeline = gsap.timeline({
      paused: true,
      defaults: { duration: 0.25 },
    });
    timeline
      .to(arrowRef.current, { rotation: "-45deg" }, "<")
      .to(imageRef.current, { scale: 1.1, opacity: 0.9 }, "<")
    timelineRef.current = timeline;
  }, []);
  const handleClickSite = () => {
    window.open(link, '_blank');
  }
  const handleClickGithub = (e) => {
    e.stopPropagation();
  }
  const handleMouseEnter = () => {
    if (!isMobile && !isTablet) {
      timelineRef.current.play();
    }
  }
  const handleMouseLeave = () => {
    if (!isMobile && !isTablet) {
      timelineRef.current.reverse();
    }
  }
  return (
    <div ref={divRef} onClick={handleClickSite} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={`flex flex-col md:flex-row md:justify-between md:items-start rounded-sm border-borderColor border-2 p-8`}>
      <div className={`md:w-[38%] overflow-hidden`}>
        <img ref={imageRef} src={image} alt={name} className={`w-full h-full lg:opacity-50`}/>
      </div>
      <div className={`md:w-[58%] text-stone-400 mt-2 md:mt-0 md:mx-8 space-y-4`}>
        <div className={`flex justify-between text-sm font-light`}>
          <p>{role}</p>
          <a href="https://www.github.com" target="blank" onClick={(e) => handleClickGithub(e)} className={`md:hidden underline underline-offset-2`}>Source Code</a>
        </div>
        <h1 className={`text-red text-2xl font-semibold`}>{name}</h1>
        <p className={`font-light`}>{description}</p>
        <div className={`hidden lg:flex items-center`}>
          {
            tech.map((tech, index) => {
              return(
                <>
                  <img src={tech} alt={techName[index]} className={`w-[35px] mr-2`}/>
                  <p className={`mr-8`}>{techName[index]}</p>
                </>
              )
            })
          }
        </div>
        <a href={sourceCode} target="blank" onClick={(e) => handleClickGithub(e)} className={`hidden md:inline-block underline underline-offset-2 hover:text-red`}>Source Code</a>
      </div>
      <img ref={arrowRef} src={Arrow} alt="Arrow icon to open project link" className={`hidden w-[40px] md:inline-block md:self-start md:-rotate-45 lg:rotate-0`}/>
    </div>
  )
};