export default function SkillCard({ name, icon, description, isTech }) {
  return (
    <div className={`flex items-center rounded-sm border-borderColor border-2 ${isTech ? 'p-2 space-x-3' : 'p-4 space-x-4'}`}>
      <div>
        <img src={icon} alt={name} className={`${isTech ? 'w-[50px] h-[50px]' : 'max-w-[75px] h-auto'}`}/>
      </div>
      <div className={`${!isTech && 'space-y-1'}`}>
        <h2 className={`text-white`}>{name}</h2>
        <p className={`text-secondary text-sm font-light`}>{description}</p>
      </div>
    </div>
  )
};