import { useRef } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Landing({isMobile, isTablet}) {
  const backgroundRef = useRef(null),
        firstNameRef = useRef(null),
        lastNameRef = useRef(null),
        subtext1Ref = useRef(null),
        subtext2Ref = useRef(null),
        arrowRef = useRef(null);
  useGSAP(() => {
    ScrollTrigger.create({
      trigger: backgroundRef.current,
      start: 'top top',
      end: 'bottom top',
      pin: true,
      pinSpacing: false,
    })
    const tl = gsap.timeline();
    tl.from([firstNameRef.current, lastNameRef.current], {x: (index) => index === 0 ? -window.innerWidth : window.innerWidth, duration: 2, ease: 'power4.out'}) // name animation
      .from([subtext1Ref.current, subtext2Ref.current], {yPercent: 50, opacity: 0, duration: 2, ease: 'power4.out', stagger: isMobile || isTablet ? 1 : 0}, 1) // description animation
      .fromTo(arrowRef.current, {yPercent: -100, opacity: 0}, {yPercent: 150, opacity: 1, repeat: -1, duration: 3, ease: 'power3.inOut'}, 0); // arrow animation
  })
  return (
    <div ref={backgroundRef} className={`w-screen h-dvh lg:h-[100vh] flex flex-col justify-center px-[1rem] md:px-[3rem] lg:px-[4rem] space-y-12 -z-10`}>
      <div className={`antonio md:flex md:justify-between text-red text-[10rem] md:text-[18.5vw] font-medium lg:font-bold text-center leading-none`}>
        <h1 ref={firstNameRef}>JASON</h1>
        <h1 ref={lastNameRef}>ZHANG</h1>
      </div>
      <div className={`lg:flex lg:justify-between text-center font-light lg:mx-[1rem] space-y-6 lg:space-y-0`}>
        <h2 ref={subtext1Ref} className={`lg:text-start`}><span className={`font-bold bg-red/30`}>FRONTEND DEVELOPER</span><br/>WHO MAKES THE INTERNET A BIT MORE FUN</h2>
        <h2 ref={subtext2Ref} className={`lg:text-end`}>CURRENTLY<br className={`hidden lg:inline-block`}/> BASED IN NORTH CAROLINA</h2>
      </div>
      <h1 ref={arrowRef} className={`text-4xl text-red font-bold absolute bottom-6 left-1/2 -translate-x-1/2`}>↓</h1>
    </div>
  )
};

{/**
  window.addEventListener('resize', function(){
    ScrollTrigger.refresh();
  });
*/}