import { useRef } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Contacts() {
  const bgRef = useRef(null);
  const handleEmailClick = () => {
    window.location.href = "mailto:hello@jasonzhang.studio";
  };
  useGSAP(() => {
    ScrollTrigger.create({
      trigger: bgRef.current,
      start: 'top top',
      end: 'bottom top',
      pin: true,
      pinSpacing: false,
    })
    setTimeout(() => ScrollTrigger.refresh(), 2000);
  }, []);
  return (
    // (adding absolute to bgRef element fixed the problem of not being able to make the button clickable due to overlay)
    <div ref={bgRef} className={`w-screen h-screen transform -translate-y-full absolute flex flex-col justify-center items-center px-[1rem] md:px-[3rem] lg:px-[4rem] space-y-12 -z-10`}>
      <button onClick={handleEmailClick} className={`antonio text-red text-[18vw] font-semibold underline leading-none md:hidden`}>CONTACT ME</button>
      <button onClick={handleEmailClick} className={`antonio hidden md:inline-block text-red md:text-[7vw] lg:text-[8.5vw] font-semibold lg:font-bold tracking-wide underline leading-none`}>HELLO@JASONZHANG.STUDIO</button>
      <div className={`w-full lg:flex lg:justify-between text-center space-y-6 lg:space-y-0 font-light`}>
        <p className={`lg:text-start`}>ALL GOOD THINGS <br className={`hidden lg:inline-block`}/> COME TO AN END,<br className={`lg:hidden`}/> BUT LET'S NOT JUST END HERE!</p>
        <p className={`lg:text-end`}>SHOOT ME A MESSAGE <br className={`hidden lg:inline-block`}/> IF YOU HAVE <br className={`lg:hidden`}/> ANY FEEDBACK, IDEAS, OR JUST WANNA SAY HI!</p>
      </div>
      <p className={`absolute bottom-6`}>DESIGNED & BUILT WITH &lt;3</p>
    </div>
  )
};

/**
 * 
 *   useEffect(() => {
    console.log(bgRef.current.getBoundingClientRect().y);
    ScrollTrigger.create({
      trigger: bgRef.current,
      start: 'top top',
      end: 'bottom top',
      pin: true,
      pinSpacing: false,
      markers:true,
    })
  })
 */