import Landing from "./Landing";
import Main from "./Main";
import Contacts from "./Contacts";

export default function Wrapper() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isTablet = navigator.maxTouchPoints > 0;
  return (
    <div className={`w-screen select-none overflow-hidden`}>
      <Landing isMobile={isMobile} isTablet={isTablet}/>
      <Main isMobile={isMobile} isTablet={isTablet}/>
      <Contacts />
    </div>
  )
};